<template>
    <div class="container" v-if="!loadingPage">
        <!-- companyForm -->
        <div class="row">
            <div class="col-12 py-14">
                <div class="p-fluid container-fluid">
                    <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                        @click="goBack" />

                    <!-- create Company Header -->
                    <div 
                        style="
                            display: flex; 
                            justify-content: space-between;
                        "
                        v-if="createPage"
                    >
                        <h3> Create Company</h3>
                        <!-- Usability Update 04-03-2024 Hide Selected Companies -->
                        <!-- <div class="mb-2 md:m-0 p-as-md-center">
                            <div class="mb-2 md:m-0 p-as-md-center">
                                <label><strong>Selected</strong> Companies</label>
                                <InputSwitch v-model="form.hasPrioritary" style="margin-left: 0.7rem;" />
                            </div>
                        </div> -->
                    </div>

                    <!-- Edit Company Header -->
                    <div 
                        style="
                            display: flex; 
                            justify-content: space-between;
                        " 
                        v-else
                    >
                        <h3 v-if="selectedTopic"> Edit Company - {{ form.name }} </h3>
                        <h3 v-else> Edit Company </h3>
                        <!-- Usability Update 04-03-2024 Hide Selected Companies -->
                        <!-- <div class="mb-2 md:m-0 p-as-md-center">
                            <div class="mb-2 md:m-0 p-as-md-center">
                                <label><strong>Selected</strong> Companies</label>
                                <InputSwitch v-model="form.hasPrioritary" style="margin-left: 0.7rem;" />
                            </div>
                        </div> -->
                    </div>

                    <!-- name -->
                    <div class="row">
                        <div class="col-12">
                            <div class="field my-3">
                                <label for="title">Name</label>
                                <InputText 
                                    id="name" 
                                    v-model.trim="form.name" 
                                    @blur="v$.form.name.$touch" 
                                    required 
                                    autofocus
                                    :class="{ 'p-invalid': !form.name }" 
                                />
                            </div>
                        </div>
                    </div>
                    <!-- Type,country, numberOf Employees -->
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4">
                            <div class="my-3">
                                <label for="CompanyType"> CompanyType</label>
                                <Dropdown 
                                    v-model="form.companyTypeId" 
                                    :options="companyTypes" 
                                    optionLabel="name"
                                    option-value="companyTypeId" 
                                    placeholder="Company Types"
                                    :class="{ 'p-invalid': !form.companyTypeId }" 
                                    :filter="true" 
                                    :loading="loading" 
                                />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4">
                            <div class="my-3">
                                <label for="Country">Country</label>
                                <Dropdown 
                                    v-model="form.countryId" 
                                    :options="countries" 
                                    option-label="name"
                                    option-value="countryId" 
                                    placeholder="Country" 
                                    :filter="true" 
                                    :loading=loading 
                                />
                                <!-- :class="{ 'p-invalid': !form.countryId }" -->
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4">
                            <div class="my-3">
                                <label for="numberOfEmployees">Number of Employees</label>
                                <!-- <InputNumber
                                    id="numberEmployees"
                                    v-model="form.numberEmployees"
                                    autofocus 
                                    suffix=" employees"
                                /> -->

                                <InputText 
                                        type="number"
                                        min="0"
                                        v-model="form.numberEmployees"
                                    />
                            </div>
                        </div>
                    </div>

                    <!-- Description -->
                    <div class="row">
                        <div class="col-12">
                            <label for="description">Description</label>
                            <div class="field my-3">
                                <Textarea
                                    style="
                                        width: 100%;
                                    "
                                    id="description"
                                    v-model.trim="form.description"
                                    rows="5"
                                    cols="30"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Topic Description -->
                    <div class="row" >
                        <div class="col-12">
                            <label v-if="selectedTopic" >
                                {{ selectedTopic.name}} - Topic Related Description
                            </label>
                            <div class="field my-3">
                                <Textarea
                                    style="
                                        width: 100%;
                                    "
                                    id="description"
                                    v-model="CompanyTopicsDescription"
                                    rows="5"
                                    cols="30"
                                />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

        <div v-if="createCompanyError" class="row">
            <div class="col-12 py-14"> 
                <div class="p-fluid container-fluid">
                    <h5 style="color: red;">- {{ createCompanyErrorMessage  }} </h5>
                </div>
            </div>
        </div>

        <div v-if="editCompanyError" class="row">
            <div class="col-12 py-14"> 
                <div class="p-fluid container-fluid">
                    <h5 style="color: red;">- {{ editCompanyErrorMessage  }} </h5>
                </div>
            </div>
        </div>

        <!-- company Create Edit Button company  -->
        <div class="row">
            <div class="col-12 p-4">
                <div class="d-flex justify-content-end px-3" v-if="!form.companyId" style="gap: 1rem;">
                    <Button
                        class="p-button"
                        :loading="loading"
                        :disabled="this.v$.form.$invalid || loading"     
                        @click="createNewCompany" 
                    >
                        <label style="margin-right: 0.5rem;" >Create Company</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                    </Button>

                    <Button
                        class="p-button-success " 
                        :loading="loading"
                        :disabled="this.v$.form.$invalid || loading" 
                        @click="createCompanyGoBack" 
                    >
                        <label style="margin-right: 0.5rem;">Create Company and Go Back</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                    </Button>

                </div>
                <div class="d-flex justify-content-end px-3" style="gap: 1rem;" v-else>
                    <Button
                        class="p-button"
                        :loading="loading"
                        @click="editCompanyFields" 
                    >
                        <label style="margin-right: 0.5rem;" >Save</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                    </Button>

                    <Button
                        class="p-button-success " 
                        :loading="loading"
                        @click="editCompanyFieldsGoBack" 
                    >
                        <label style="margin-right: 0.5rem;">Save and Go Back</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                    </Button>

                </div>
            </div>
        </div>

        <!-- SWOT -->
        <div class="row">
            <SwotListTopicId
                :companyId="selectedElement"
            >
            </SwotListTopicId>
        </div>

        <!-- DOCUMENT LIST -->
        <div class="col-12 py-4" v-if="!createPage">
            <DocumentsList 
                :company-id="form.companyId"
            />
        </div>
        
        <!-- PROGRAM LIST -->
        <div class="col-12 py-4" v-if="!createPage">
            <ProgramsList 
                :company-id="form.companyId"
            />
        </div>
    </div>
    <div class="container" v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>

import Button from 'primevue/button';
// import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

//import Create Company service 
import { createCompany, getCompaniesTopicDescription, editCompany} from '../../../../company/services/company.service';

// IMPORT GETDOCUMENTS FROM TOPIC-OVERVIEW
import DocumentsList from '../components/DocumentList.vue'
// import DocumentsList from '../../../../document/components/DocumentsList.vue';
import ProgramsList from '../components/ProgramsList.vue';

import SwotListTopicId from '../components/SwotListTopicId.vue';

import { useToast } from 'vue-toastification';
const  toast = useToast();

export default {
    name: 'CreateEditCompanyByTopicPage',
    components: {
    Button,
    // InputSwitch,
    InputText,
    Dropdown,
    Textarea,
    ProgressSpinner, 

    // import Document, Program
    DocumentsList,
    ProgramsList,

    SwotListTopicId,
},
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            createPage: true,
            loading: false,
            form: {
                companyId : null, 
                name: null,
                companyTypeId: null,
                countryId: null,
                description: null,
                address: "NotAssigned",
                numberEmployees: null,
                hasPrioritary: true,
            },
            CompanyTopicsDescription: null,
            responseCompanyTopics: {
                id: null,
                companyId: null, 
                topicId: null,
                description: null, 
            },
            createCompanyError:false,
            createCompanyErrorMessage:'',

            editCompanyError:false,
            editCompanyErrorMessage:'',

            loadingPage: true, 
            companyParams: null, 
        }
    },
    validations() {
        return {
            form: {
                name: { required },
                companyTypeId: { required },
            }
        }
    },

    // async created(){

    // },
    async mounted() {
        await this.getCompanyTypes();
        await this.getCountries();
        // console.log(this.selectedElement);
        
        if(this.selectedElement){
            this.loading = true; 
            this.companyParams = this.selectedElement
            await this.getCompanyById();
            await this.assingCompanyTopicDescription();
            this.assignCompany(this.company);
            this.loading = false;
            this.loadingPage= false;
        }
        if(this.createPage){
            this.CompanyTopicsDescription = `${this.selectedTopic.name} description \nAdd a topic description to include this company in ${this.selectedTopic.name}`
            this.loadingPage= false;
        }
    },
    computed: {
        selectedElement(){
            return this.$route.params.companyId
        },
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        companyTypes() {
            return this.$store.getters.obtainCompanyTypes;
        },
        countries(){
            return this.$store.getters.obtainCountries;
        },
        company(){
            return this.$store.getters.obtainCompany;
        },
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        async getCompanyTypes() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCompanyTypes');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getCountries(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },

        async createNewCompany(){
            try {
                this.createCompanyError=false, 
                this.createCompanyErrorMessage='', 
                this.loading = true
                let company ;
                if(this.CompanyTopicsDescription){
                    company = { 
                        ...this.form, 
                        CompanyTopics: [
                            {
                                topicId:this.selectedTopic.topicId,
                                description: this.CompanyTopicsDescription
                            }
                        ]
                    }
                }
                else{
                    company = { 
                        ...this.form, 
                    }
                }
                if(!this.v$.form.$invalid){
                    const response = await createCompany(company);

                    if(response.data.companyTopics.length > 0){
                        this.assignCompanyTopicDescription(response.data);
                    }
                    this.assignCompany(response.data);
                    this.companyParams = response.data.companyId

                    toast.success('Company created successfully');
                    this.loading= false;
                }
                else{
                    toast.error('Fill Required Data');
                    this.createCompanyError =true; 
                    this.createCompanyErrorMessage= 'Fill Required Data';
                    this.loading= false;
                }

            } catch (error) {
                toast.error(error.message);
                this.createCompanyError =true; 
                this.createCompanyErrorMessage=error.message;
                this.loading = false;
            }
        },

        async createCompanyGoBack(){
            await this.createNewCompany();
            if(!this.createCompanyError){
                this.$router.go(-1)
            }
        },

        assignCompanyTopicDescription(response){
            this.CompanyTopicsDescription = response.companyTopics[0].description;

            this.responseCompanyTopics.id = response.companyTopics[0].id;
            this.responseCompanyTopics.companyId = response.companyTopics[0].companyId;
            this.responseCompanyTopics.topicId = response.companyTopics[0].topicId;
            this.responseCompanyTopics.description = response.companyTopics[0].description;
        },

        // Edit Methods 

        async getCompanyById(){
            try {
                await this.$store.dispatch('obtainCompaniesByCompanyId',this.selectedElement)
            } catch (error) {
                console.error(error);
            }
        }, 

        assignCompany(response){
            // console.log(response);
            this.form = response
            // this.form.companyId = response.companyId
            // this.form.name = response.name
            // this.form.companyTypeId = response.companyTypeId
            // this.form.countryId = response.countryId
            // this.form.description = response.description
            // this.form.numberEmployees = response.numberEmployees
            // this.form.hasPrioritary = response.hasPrioritary


            // form: {
            //     companyId : null, 
            //     name: null,
            //     companyTypeId: null,
            //     countryId: null,
            //     description: null,
            //     address: "NotAssigned",
            //     numberEmployees: null,
            //     hasPrioritary: true,
            // },

            // responseCompanyTopics: {
            //     id: null,
            //     companyId: null, 
            //     topicId: null,
            //     description: null, 
            // },

            this.createPage = false; 
        },
        
        
        async editCompanyFields(){
            try {
                this.loading = true; 
                let company; 
                //  validate companyTopicDescription if exist or not 

                const editForm = {
                    address: this.form.address,
                    companyId: this.form.companyId,
                    companyTypeId: this.form.companyTypeId,
                    countryId : this.form.countryId,
                    dateCreated: this.form.dateCreated,
                    dateUpdated: this.form.dateUpdated, 
                    description: this.form.description, 
                    // hasPrioritary: this.form.hasPriority,
                    name: this.form.name,
                    numberEmployees: this.form.numberEmployees,
                }

                if(this.responseCompanyTopics.id){
                    //  if companyTopicDescription exist on this company
                    company = { 
                        ...editForm,
                        companyTopics: [
                            {
                                id: this.responseCompanyTopics.id,
                                companyId: this.responseCompanyTopics.companyId,
                                topicId: this.responseCompanyTopics.topicId,
                                description: this.CompanyTopicsDescription
                            }
                        ]
                    }
                }else{
                    if(this.CompanyTopicsDescription){
                        // if new CompanyTopicsDescription is added 
                        company = {
                            ...editForm,
                            companyTopics: [
                                {
                                    topicId:this.selectedTopic.topicId,
                                    description: this.CompanyTopicsDescription
                                }
                            ]
                        }
                    }
                    else{
                        //  CompanyTopicsDescription not adding 
                        company = {
                            ...editForm,
                            companyTopics:[]
                        }
                    }
                }
                // await this.$store.dispatch('modifyCompany', company)
                await editCompany(this.companyParams , company);
                await this.assingCompanyTopicDescriptionAfterUpdate() // for update the topicDescription

                toast.success('Company saved!');
                this.editCompanyError = false,
                this.editCompanyErrorMessage = '',
                this.loading = false; 
                
            } catch (error) {
                toast.error(error.message);
                this.editCompanyError = true;
                this.editCompanyErrorMessage = error.message ;
                
                this.loading = false; 
            }
        },

        async editCompanyFieldsGoBack(){
            await this.editCompanyFields();
            if(!this.editCompanyError){
                this.$router.go(-1)
            }
        },

        async assingCompanyTopicDescription(){
            try {
                // const response = await getCompaniesTopicDescription(this.selectedElement, this.selectedTopic.topicId);
                const response = await getCompaniesTopicDescription(this.companyParams, this.selectedTopic.topicId);
                this.CompanyTopicsDescription = response.description;
                this.responseCompanyTopics = response;
            } catch (error) {
                console.log(error);
            }
        },

        async assingCompanyTopicDescriptionAfterUpdate(){
            try {
                // const response = await getCompaniesTopicDescription(this.selectedElement, this.selectedTopic.topicId);
                const response = await getCompaniesTopicDescription(this.companyParams, this.selectedTopic.topicId);
                this.CompanyTopicsDescription = response.description;
                this.responseCompanyTopics = response;
            } catch (error) {
                this.responseCompanyTopics.id = null;
                this.responseCompanyTopics.companyId = null;
                this.responseCompanyTopics.topicId = null;
                this.responseCompanyTopics.description = null;
            }
        }
        
    },

    watch: {
    },
};
</script>

<style scoped></style>